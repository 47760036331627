<template>
  <div class="c-app modal-vue miner-info support tickets">
    <CWrapper class="bg-bg-gray pt-2">
      <div class="c-title pb-4">{{ $t("Ticket") }} № {{ ticketInfo.id }}</div>
      <CRow>
        <CCol md="3">
          <CCard>
            <CCardHeader> №: {{ ticketInfo.id }} </CCardHeader>
            <CCardBody>
              <div>
                <span>{{ $t("Topic") }}</span>
                {{ ticketInfo.subject }}
              </div>
              <div v-if="ticketInfo.created_at">
                <span>{{ $t("Date") }}</span>
                {{
                  new Date(ticketInfo.created_at ? ticketInfo.created_at : '').toISOString().split("T")[0] +
                  " " +
                  new Date(ticketInfo.created_at ? ticketInfo.created_at : '').toLocaleTimeString()
                }}
              </div>
              <div>
                <span>{{ $t("Message") }}</span>
                {{ ticketInfo.body }}
              </div>
              <div>
                <span>{{ $t("Farm") }}</span>
                {{ ticketInfo.farm ? ticketInfo.farm.name : "" }}
              </div>
              <div class="mt-2" v-if="ticketInfo.attachments && ticketInfo.attachments.length > 0">
                <a :href="hostUrl + '/storage/' +ticketInfo.attachments[0].file_path.split('public/')[1]" target="blank">
                  <img :src="hostUrl + '/storage/' +ticketInfo.attachments[0].file_path.split('public/')[1]" alt="" width="200px" style="object-fit: cover;">
                </a>
              </div>
            </CCardBody>
            <CCardFooter v-if="userInfo && userInfo.user.role !== 'customer'">
              <p class="mb-2 mt-4">{{ $t("Status") }}</p>
              <CInputRadioGroup
                :checked.sync="ticketInfo.status"
                :options="[
                  { value: 'open', label: 'Open' },
                  { value: 'inprogress', label: 'in Progress' },
                  {
                    value: 'done',
                    label: 'Done',
                  },
                ]"
                class="text-gray"
                name="status"
                @update:checked="updateValues"
              >
              </CInputRadioGroup>
            </CCardFooter>
          </CCard>
        </CCol>
        <CCol md="9">
          <div class="message-inner text-main_black mb-4">
            <div class="title font-weight-bolder">{{ $t("Comments") }}</div>
            <div class="message-wrap">
              <div
                class="d-flex align-items-start mt-3"
                v-for="(message, key) in ticketMessages"
                :key="key"
              >
                <img
                  class="message-img"
                  :src="
                    message.image
                      ? hostUrl + message.image
                      : require('@/assets/icons/users/avatar.png')
                  "
                  alt=""
                />
                <div class="message-text">
                  <div class="message-title">
                    {{ message.first_name + " " + message.last_name }}
                  </div>
                  <div class="my-1">{{ message.body }}</div>
                  <span class="text-gray"> added {{ message.created_at }} </span>
                </div>
              </div>
            </div>
            <form class="message-send">
              <textarea class="mb-3" v-model="messageBody.body"> </textarea>
              <div class="w-full d-flex justify-content-end">
                <CButton class="button-white border-gray text-gray mr-3">{{
                  $t("Cancel")
                }}</CButton>
                <CButton
                  class="button-blue"
                  @click="
                    ADD_MESSAGE({
                      body: { ...messageBody, user_id: userInfo.id },
                      id: page,
                    })
                  "
                  >{{ $t("Send") }}</CButton
                >
              </div>
            </form>
          </div>
        </CCol>
      </CRow>
    </CWrapper>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Support",
  components: {},
  data() {
    return {
      page: parseInt(this.$route.params.id),
      hostUrl: process.env.VUE_APP_API,
      messageBody: {
        body: "",
        attachment: 0,
        ticket_id: parseInt(this.$route.params.id),
      },
      updateBody: {},
      modalInfo: {
        curUser: null,
        date: null,
        collapseType: false,
        collapseStatus: false,
      },
      ticketInfo: {},
      messages: [],
    };
  },
  created() {
    this.curPage();
    this.GET_TICKET(this.page).then((res) => {
      this.ticketInfo = res.data;
    });
    this.GET_MESSAGES(this.page);
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    ...mapState("miners", ["ticketMessages"]),
  },
  methods: {
    ...mapActions("miners", ["GET_TICKET", "GET_MESSAGES", "ADD_MESSAGE", "EDIT_TICKET"]),
    curPage() {
      this.$emit("curPage", "tickets");
    },
    updateValues(value) {
      // modalInfo current user listen changes
      this.EDIT_TICKET({ body: { status: value }, id: this.page });
    },
  },
};
</script>

<style scoped lang="sass">
.miners
  &-well, &-danger, &-total
    padding: 6px 16px
    border-radius: 8px
    font-weight: 500
    font-size: 12px
    color: white
  &-well
    background: #78CD5A
  &-danger
    background: #F42A4E
  &-total
    background: #C2C2C2
</style>
