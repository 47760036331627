var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-app modal-vue miner-info support tickets"},[_c('CWrapper',{staticClass:"bg-bg-gray pt-2"},[_c('div',{staticClass:"c-title pb-4"},[_vm._v(_vm._s(_vm.$t("Ticket"))+" № "+_vm._s(_vm.ticketInfo.id))]),_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[_c('CCard',[_c('CCardHeader',[_vm._v(" №: "+_vm._s(_vm.ticketInfo.id)+" ")]),_c('CCardBody',[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("Topic")))]),_vm._v(" "+_vm._s(_vm.ticketInfo.subject)+" ")]),(_vm.ticketInfo.created_at)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("Date")))]),_vm._v(" "+_vm._s(new Date(_vm.ticketInfo.created_at ? _vm.ticketInfo.created_at : '').toISOString().split("T")[0] + " " + new Date(_vm.ticketInfo.created_at ? _vm.ticketInfo.created_at : '').toLocaleTimeString())+" ")]):_vm._e(),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("Message")))]),_vm._v(" "+_vm._s(_vm.ticketInfo.body)+" ")]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("Farm")))]),_vm._v(" "+_vm._s(_vm.ticketInfo.farm ? _vm.ticketInfo.farm.name : "")+" ")]),(_vm.ticketInfo.attachments && _vm.ticketInfo.attachments.length > 0)?_c('div',{staticClass:"mt-2"},[_c('a',{attrs:{"href":_vm.hostUrl + '/storage/' +_vm.ticketInfo.attachments[0].file_path.split('public/')[1],"target":"blank"}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.hostUrl + '/storage/' +_vm.ticketInfo.attachments[0].file_path.split('public/')[1],"alt":"","width":"200px"}})])]):_vm._e()]),(_vm.userInfo && _vm.userInfo.user.role !== 'customer')?_c('CCardFooter',[_c('p',{staticClass:"mb-2 mt-4"},[_vm._v(_vm._s(_vm.$t("Status")))]),_c('CInputRadioGroup',{staticClass:"text-gray",attrs:{"checked":_vm.ticketInfo.status,"options":[
                { value: 'open', label: 'Open' },
                { value: 'inprogress', label: 'in Progress' },
                {
                  value: 'done',
                  label: 'Done',
                } ],"name":"status"},on:{"update:checked":[function($event){return _vm.$set(_vm.ticketInfo, "status", $event)},_vm.updateValues]}})],1):_vm._e()],1)],1),_c('CCol',{attrs:{"md":"9"}},[_c('div',{staticClass:"message-inner text-main_black mb-4"},[_c('div',{staticClass:"title font-weight-bolder"},[_vm._v(_vm._s(_vm.$t("Comments")))]),_c('div',{staticClass:"message-wrap"},_vm._l((_vm.ticketMessages),function(message,key){return _c('div',{key:key,staticClass:"d-flex align-items-start mt-3"},[_c('img',{staticClass:"message-img",attrs:{"src":message.image
                    ? _vm.hostUrl + message.image
                    : require('@/assets/icons/users/avatar.png'),"alt":""}}),_c('div',{staticClass:"message-text"},[_c('div',{staticClass:"message-title"},[_vm._v(" "+_vm._s(message.first_name + " " + message.last_name)+" ")]),_c('div',{staticClass:"my-1"},[_vm._v(_vm._s(message.body))]),_c('span',{staticClass:"text-gray"},[_vm._v(" added "+_vm._s(message.created_at)+" ")])])])}),0),_c('form',{staticClass:"message-send"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageBody.body),expression:"messageBody.body"}],staticClass:"mb-3",domProps:{"value":(_vm.messageBody.body)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.messageBody, "body", $event.target.value)}}}),_c('div',{staticClass:"w-full d-flex justify-content-end"},[_c('CButton',{staticClass:"button-white border-gray text-gray mr-3"},[_vm._v(_vm._s(_vm.$t("Cancel")))]),_c('CButton',{staticClass:"button-blue",on:{"click":function($event){return _vm.ADD_MESSAGE({
                    body: Object.assign({}, _vm.messageBody, {user_id: _vm.userInfo.id}),
                    id: _vm.page,
                  })}}},[_vm._v(_vm._s(_vm.$t("Send")))])],1)])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }